$tan: #DEB887;
$offwhite: #FAF9F6;

#reviews-header{
    width: 310px;
}

.review-body{
    overflow-x:auto;
}

ul{
    align-items: flex-start;
    display: flex;
    flex-wrap: nowrap;
    list-style-type: none;
    height: 267px;
    overflow-x: hidden;
    overflow-y: hidden;
}

.review-container{
    background-color: $tan;
    border-radius: 25px;
    border: 5px solid $tan;
    text-align: center;
    margin: 0px 500px;
    margin-bottom: 25px;
    p{
        height: auto;
        width: 320px;
    }
    @media only screen and (min-width: 320px) {
        margin: 0px 1000px;
    }
}

#review-link{
    text-decoration: none;
}

#experiences-link{
    color: $tan;
    border-radius: 25px;
    border: 5px solid $tan;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 300px;
}

#experiences-link:hover{
    color: $offwhite;
    background-color: $tan;
}

.control-buttons{
    display: flex;
    justify-content: space-between;
    font-style: 'Poltawski Nowy', serif;
    padding-top: 10px;
    button{
        color: $offwhite;
        background-color: $tan;
        border-radius: 100%;
        border: 1px solid $tan;
        width: 150px;
        height: 50px;
        font-size: 50px;
        font-family: 'Poltawski Nowy', serif;
        padding-bottom: 22px;
        width: 75px;
    }
    button:hover{
        color: $offwhite;
        background-color: $tan;
    }
}