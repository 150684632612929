$tan: #DEB887;

#collab{
    max-width: 570px;
}

.icons{
    margin-left: auto;
    margin-right: auto;

    .link-icon{
        width: 40px;
        height: 40px;
        padding: 10px;
        margin: 0px 20px;
    }
}

.disclaimer{
    p{
        color: $tan; 
    }
}