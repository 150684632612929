$tan: #DEB887;

#contact-us{
    width: 320px;
}

.text-body-contact{
    background-color: $tan;
    border-radius: 25px;
    border: 5px solid $tan;
    text-align: center;
    width: 520px;
    padding-bottom: 10px;
    margin-left: auto;
    margin-right: auto;

    @media only screen and (max-width: 520px){
        width: 320px;
    }
}