$tan: #DEB887;
$offwhite: #FAF9F6;

.outer-page{
    padding: 0px 25px;
}

.page{
    margin: 0px 25px;
    text-align: center;
}


.header-image{
    width: 400px;
    height: 350px;
    border-radius: 25px;
    border: 5px solid white;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 590px){
    .header-image{
        width: 320px;
        height: 350px;
    }
}

.main-header{
    text-align: center;
    font-size: 25px;

    #ppc{
        text-transform: uppercase;
    }

    h1{
        border-radius: 25px;
        border-bottom: 5px solid $tan;
        margin-left: auto;
        margin-right: auto;
    }

    @media only screen and (min-width: 590px){
        h1{
            width: 650px;
        }
    }
}

.icon{
    color: $tan;
}

.lower-header{
    background-color: $tan;
    border-radius: 25px;
    border: 5px solid $tan;
    text-align: center;
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
}

.header{
    text-align: center;
    padding-bottom: 10px;
}

.text-body{
    background-color: $tan;
    border-radius: 25px;
    border: 5px solid $tan;
    text-align: center;
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
}

p{
    color: $offwhite;
    line-height: 1.3;
    font-size: 19px;
    padding-top: 10px;
}

.stand-alone-image{
    padding-top: 15px;
    text-align: center;

    img{
        background-color: $tan;
        border-radius: 25px;
        border: 5px solid $tan;
        text-align: center;
    }

    @media only screen and (max-width: 590px){
        img{
            width: 300px;
            height: auto;
        }
    }
}

.footer{
    width: 525px;
    text-align: center;
    align-content: center;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 10px;

    @media only screen and (max-width: 590px){
        width: 320px;
    }

    .icons-home{
        display: flex;
        justify-content: space-between;
    
        .home-link{
            background-color: $tan;
            border-radius: 25px;
            border: 5px solid $tan;
            border: none;
        }
    
        .link-icon{
            width: 40px;
            height: 40px;
            padding: 10px;
            margin: 0px 20px;
        }
    }
}