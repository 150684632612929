$tan: #DEB887;

.nav-wrapper {
  display: flex;
  justify-content: space-between;
  padding:  30px;
  

  .left-side {
    a {
      margin-left: 15px;
      cursor: pointer;
      color: white;
      transition: 0.5s ease-in-out;

      &:hover {
        color: white;
      }
    }

    .nav-image{
      width: 150px;
      height: 150px;
    }
  }

  .right-side {
    display: flex;
  }

  .dropdown-button{
    padding-top: 20px;
    border: none;
    background-color: white;
    font-size: 30px;
    cursor: pointer;
    transition: all .2s ease-in-out;
  }

  .dropdown-button:hover{
    transform: scale(1.1);
  }

  #dropdown-content {
    visibility: hidden;
    opacity:0;
    transition:opacity 0.5s linear;
    background-color: white;
    box-shadow: -5px 15px 15px -5px $tan;
    padding: 5px;
    position: absolute;
    right: 30px;
  }

  form{
    display: flex;
    justify-content: flex-end;
  }

  .nav-link-wrapper {
    height: 22px;
    border-bottom: 1px solid transparent;
    margin-right: 20px;
    text-transform: uppercase;
    font-size: 0.9rem;

    a {
      color: black;
      text-decoration: none;
      &:hover {
        color: $tan;
      }
    }

    &:hover {
      border-bottom: 1px solid $tan;
    }

    .nav-link-active {
      border-bottom: 1px solid $tan;
      color: $tan;
    }
  }

  .menu-icon-image{
    width: 35px;
    height: 35px;
  }
}