$tan: #DEB887;
$offwhite: #FAF9F6;

#movie-header{
    width: 375px;
    margin-bottom: 20px;
    @media only screen and (max-width: 400px){
        width: 300px;
    }
}

#movie-link{
    text-decoration: none;
}

#movie-rental-link{
    color: $tan;
    border-radius: 25px;
    border: 5px solid $tan;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 300px;
}

#movie-rental-link:hover{
    color: $offwhite;
    background-color: $tan;
}