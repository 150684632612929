$tan: #DEB887;
$offwhite: #FAF9F6;

#order_header{
    font-size: 45px;
    border-radius: 25px;
    border-bottom: 5px solid $tan;
    width: 300px;
        margin-left: auto;
        margin-right: auto;
}

#order_form{
    color: $offwhite;
    font-size: large;
    margin: 15px;
    display: flex;
    justify-content: center;
}

#form{
    background-color: $tan;
    border-radius: 25px;
    text-align: center;
    width:500px;
    padding: 10px;
    
    h2{
        padding-bottom: 10px;
    }
}

.field{
    padding: 10px;
}

@media only screen and (max-width: 590px){
    #form{
        background-color: $tan;
        border-radius: 25px;
        text-align: center;
        width:320px;
        padding: 10px;

        h2{
            padding-bottom: 10px;
        }
    }
}

#submit_button{
    background-color: $offwhite;
    margin-left: 15px;
    cursor: pointer;
    color: black;
    transition: 0.5s ease-in-out;
    height: 30px;
    width: 150px;
    margin-top: 10px;
    padding: 2px;

    &:hover {
        background-color: $tan;
    }
}

#status{
    text-align: center;
    font-size: 25px;
}