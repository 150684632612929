html {
    font-size: 100%;
    height:100%;
}

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

body {
    overflow-x: hidden;
    font-weight: normal;
    background-color: white;
    background-size: cover;
    min-width: 320px;
    height:100%;
    font-family: 'Poltawski Nowy', serif;
}

.app {
    margin-top: 2rem;
    display: grid;
    justify-items: center;
}

.space{
    padding-top: 25px;
}