$tan: #DEB887;

#our-work{
    width: 280px;
}

.examples{
    display: grid;
    grid-template-columns: 1fr;
    justify-content: space-evenly;
    justify-items: center;
    background-color: $tan;
    border-radius: 25px;
    border: 5px solid $tan;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;

    a{
        text-decoration: none;
    }

    p{
        font-size: 25px;
        text-align: center;
        a{
            text-decoration: none;
        }
    }

    img{
        border-radius: 25px;
    }
}

.example-image{
    padding: 10px;
    width: 500px;
    height: auto;
}

@media only screen and (max-width: 590px){
    .example-image{
        width: 320px;
        height: auto;
    }
}

.example-image-custom1{
    width:auto;
}