$tan: #DEB887;
$offwhite: #FAF9F6;

.react-calendar { 
    width: 400px;
    max-width: 100%;
    background-color: #fff;
    color: #222;
    border-radius: 8px;
    box-shadow: 0 10px 25px $tan;
    line-height: 1.125em;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 400px){
    .react-calendar { 
        width: 320px;
    }
}

.react-calendar__navigation button {
    color: $tan;
    min-width: 44px;
    background: none;
    font-size: 16px;
    margin-top: 8px;
}

abbr[title] {
    text-decoration: none;
}

.react-calendar__month-view__days__day--weekend {
    color: black;
}

.react-calendar__month-view__days__day--weekend {
    color: black;
}

.react-calendar__tile--now {
    background: rgba(215, 138, 38, 0.197);
    border-radius: 6px;
    font-weight: bold;
    color: $tan;
}

.react-calendar__tile--active {
    background: $tan;
    border-radius: 6px;
    font-weight: bold;
    color: white;
    .highlight {
        color:red;
    }
    
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: $tan;
    color: white;
}

.footer-date-checker{
    width: 400px;
    background-color: $tan;
    border-radius: 25px;
    border: 5px solid $tan;
    text-align: center;
    align-content: center;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 10px;

    @media only screen and (max-width: 590px){
        width: 300px;
    }

    a{
        color: $offwhite;
        text-decoration: none;
        font-size: 19px;
        border: dotted 2.5px $offwhite;
    }
}

@media only screen and (max-width: 400px){
    .footer { 
        width: 320px;
    }
}

.disable {
    color:red;
    pointer-events: none;
}